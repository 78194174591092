import React, { Component } from "react";

import img1 from './slider/1.jpg'
import img2 from './slider/2.jpg'

import img5 from './slider/5.jpg'
import img6 from './slider/6.jpg'
import img7 from './slider/7.jpg'
import img8 from './slider/8.jpg'
import img9 from './slider/9.jpg'

import { Box } from "@mui/material";
import { Image } from "antd";



export default class Responsive extends Component {
  render() {
    
    return (
      <Box>
        <h2> GALLERY </h2>
        {/* <Slider arrows={0} {...settings} pauseOnHover={0}> */}
         
          <div className="row">

         
            {
              [img1,img2,img5,img6,img7,img8,img9].map((e)=>{
                return <div className="col-12 col-sm-6 col-lg-4 mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 
                 
                }}
                >
                 <Image src={e} alt=""  width={'100%'} loading="lazy"   className="text-center p-3" style={{ border: "1px solid #1573AF",zIndex:'1000'}}/>
                 <hr 
                 style={{
                 width:'0%',
                  height:'80px',
                 }}
                 />
                </div>
              })
            }
            </div>
         
         
          
        {/* </Slider> */}
      </Box>
    );
  }
}