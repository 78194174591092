import React from 'react';
import { Tabs } from 'antd';
import { Box, Container } from '@mui/material';
import Gratitude from './gratitude';
import Partners from './partnertap';
import { Contx } from '../../../App';
import bgimg from '../about/backimg.png'



const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: '1',
    label: `Partners`,
    children: <Partners/>,
  },
  {
    key: '2',
    label: `Our gratitude `,
    children: <Gratitude/>,
    
  },
 
];




const App = () => {

    const {lang} = React.useContext(Contx);
    const dirct = lang === 'ar' ? 'rtl' : 'ltr';
    React.useEffect(() => {
      window.scrollTo(0, 0)
  }, [])

return(

<Box m={4} >

  <img src={bgimg} alt="" style={{
    position: 'absolute',
    width: '90%',
    top: '30%',
    right: '2%',
    opacity: '0.3',
  }}/>

  <Container  >
    <Tabs defaultActiveKey="1"  onChange={onChange}  size='large' type='card'  ClassName='poptap' cardBg='#000' tabBarStyle={{
      direction: dirct,
      textAlign: 'center',
      fontWeight: 'bold',
      
    }} >
      {items.map(item => (
        <Tabs.TabPane  key={item.key} tab={item.label} >
          {item.children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  </Container>
</Box>);
}
export default App;