import React from 'react';
import './contact.css';
import { Contx as MyContext } from '../../../App';

import 'leaflet/dist/leaflet.css';
import icon1 from './icons/tel.svg'
import icon2 from './icons/email.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import bandw from './bandw.png'



import emailjs from 'emailjs-com';






const Contact = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { lang } = React.useContext(MyContext);
    const data = lang === 'en' ? {
        dir: 'ltr',
        title: 'Contact Us',
        contact1: 'Contact Erbil Branch',
        contact2: 'Contact Baghdad Branch',
        name: 'Name',
        email: 'Email',
        phone: 'Phone Number',
        message: 'Message',
        send: 'Send'
    } : {
        dir: 'rtl',
        title: 'تواصل معنا',
        contact1: 'تواصل مع فرع اربيل',
        contact2: 'تواصل مع فرع بغداد',
        name: 'الاسم',
        email: 'البريد الالكتروني',
        phone: 'رقم الهاتف',
        message: 'الرسالة',
        send: 'ارسال'
    }

 






    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e.target)

        emailjs.sendForm('service_6w62c43', 'template_47sre5z', e.target, 'vQ9tfUhCEgG94XuCk')
            .then((result) => {
                console.log(result.text);
                
                // set timmer to show sent message
                setTimeout(() => {
                    document.getElementById('sup').innerHTML = ''
                    e.target.reset();
                   
                }
                    , 3000);
                document.getElementById('sup').innerHTML = 'sent succesfully'

                 
            }
            , (error) => {
                console.log(error.text);
            }
            );
        


    };


    return (
        <div className='Container' style={{ padding: '5%',position:'relative', overflow: 'hidden' }} dir={data.dir}>
        
            <img src={bandw} alt="" style={{
                position: 'absolute',
                top: '60vh',
                left: '-10%',
                zIndex: '-1',
             
                width: '30%',
                opacity: '0.5',
                filter: 'grayscale(100%)',
            }} />
             <img src={bandw} alt="" style={{
                position: 'absolute',
                top: '18vh',
                right: '-15%',
                zIndex: '-1',
                opacity: '0.5',
                filter: 'grayscale(100%)',
             
                width: '30%',
            }} />



            <h1 style={{ textAlign: 'center' }}>{data.title}</h1>
            <div className='row  d-flex justify-content-center' style={{ gap: '4% 4%' }}>


                <div className='col-11 col-md-6 m-2 p-4'
                    style={{
                        width: '600px',
                        height: '350px',
                        borderRadius: '10px',
                        position: 'relative',

                        backgroundColor: '#84b086',
                    }}
                >

                    <h3 style={{ textAlign: 'center', color: '#00A9A5',position:'absolute',left:'35%',top:'10px' }} id='sup'> </h3>
                    <form onSubmit={sendEmail}  >

                        <input type="text" placeholder={data.name} className='form-control mt-1' name='from_name' />
                        <input type="email" placeholder={data.email} className='form-control mt-1' name='from_email' />
                        <input type="tel" placeholder={data.phone} className='form-control mt-1' name='from_phone' />
                        <textarea placeholder={data.message} id="" cols="30" rows="3" className='form-control mt-1' name='message' ></textarea>
                        <input type="submit" value={data.send} className='form-control btn btn-primary mt-2 sup' />



                    </form>



                </div>






            </div>












            <div className="row p-5" dir='ltr'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '50px',
                }}
            >
                <hr />



                <div className='row' style={{
                    width: '300px',
                    height: '100px',
                    borderRadius: '10px',
                    border: '1px solid #008089',

                }}>
                    <div style={{

                        borderRadius: '50%',
                        padding: '10px',
                        border: '1px solid #008089',
                        width: '60px',
                        margin: '20px 0',
                        display: 'flex',


                    }}>
                        <img src={icon1} alt="" width={'40px'} />


                    </div>

                    <p className='col '
                        style={{
                            margin: '35px 0',
                            fontSize: '20px',

                        }}
                    >
                        <a href="tel:+964الرقم يتغير الى 7809190218" style={{ textDecoration: 'none', color: '#444' }}> 07809190218</a>
                    </p>
                </div>

                <div className='row' style={{
                    width: '300px',
                    height: '100px',
                    borderRadius: '10px',
                    border: '1px solid #008089',
                }}>
                    <div style={{

                        borderRadius: '50%',
                        padding: '10px',
                        border: '1px solid #008089',
                        width: '60px',
                        margin: '20px 0',
                        display: 'flex',


                    }}>
                        <img src={icon2} alt="" width={'40px'} />


                    </div>

                    <p className='col col-md'
                        style={{
                            margin: '35px 0',
                            fontSize: '16px',

                        }}
                    >
                        <a href="mailto:info@alshubber.com" style={{ textDecoration: 'none', color: '#444' }}>info@alshubber.com</a>

                    </p>
                </div>




            </div>










            <div className="row">
                

                <div className='row d-flex justify-content-center'>
                    <div className="col-12 col-md-8">
                <iframe title='map' className='col-12' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.7444672028173!2d44.41500182456121!3d33.32549537343924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557810421a4d185%3A0xabfab09816394e57!2sShubber%20Scientific%20Bureau!5e0!3m2!1sar!2siq!4v1688344414137!5m2!1sar!2siq" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> 
                </iframe>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
