import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import { Contx } from '../../App';


import logo from '../../logo.svg'
import Header from './header';
import { NavLink } from 'react-router-dom';








function ResponsiveAppBar() {

  const { lang } = React.useContext(Contx);

  const pages = lang==='en'?['Home', 'About Us', 'Partners', 'Gallery', 'Careers', 'Contact Us']:
  ['الرئيسية', 'من نحن', 'الشركاء', 'المعرض', 'الوظائف', 'اتصل بنا']
  ;
  const pageslink = ['/', '/About', '/Partners', '/Gallery', '/Careers', '/Contact'];

  const direction = lang === 'en' ? 'ltr' : 'rtl';

  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <>
      <Header />
      <AppBar position="sticky" sx={{ backgroundColor: '#fff', zIndex: '1' , direction:direction }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters >



            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <img src={logo} width={'250px'} alt="" />
            </Box>




            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#1474B0"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, i) => (
                  <NavLink to={pageslink[i]} style={{ textDecoration: 'none', color: '#1474B0' }}>
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
              </Menu>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <img src={logo} width={'250px'} alt="" />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>
              {pages.map((page, i) => (
                <NavLink to={pageslink[i]} activeclassname="active" style={{ textDecoration: 'none' }}>
                  <Typography
                  className='nave-link-hover'
                    key={page}
                    my={2}

                    mx={5}
                    color={'#1573af'}

                    sx={{  display: 'block', fontSize: '20px',fontWeight:'bold',
                  "&:hover": {
                    color: "#678c3c",
                  }
                  }}
                  >
                 
                   {page}
               
                  </Typography>
                </NavLink>
              ))}
            </Box>


          </Toolbar>
        </Container>
      </AppBar></>
  );
}
export default ResponsiveAppBar;
