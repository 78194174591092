import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import img from './1.jpeg'

import img1 from './digram.jpg'

import { Contx } from '../../../App';
import { Link } from 'react-router-dom';
import Newres from './newres';

const Home = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? date.en : date.ar
    const direction = lang === 'en' ? 'ltr' : 'rtl'

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Box >
            <Box sx={{ background: `url(${img}) `, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', direction: direction }} >
                <Box   sx={{ background: 'linear-gradient(91deg, #1474B0 0%, #688C3C 100%)', opacity: '0.7', height: '80vh' }}>


                    
                    <Typography variant="h2" data-aos="fade-right" sx={{
                        textAlign: {md:"start",xs:'center'}
                    }} paddingX={{md:'16%',xs:3}} paddingY={2} pt={20} color={'white'} >
                        {content.h}
                    </Typography>
                    <Typography   data-aos="fade-right" paddingX={{md:'16%',xs:3}} paddingY={0} color={'white'} sx={{
                        fontSize: '25px',
                        textAlign: {md:"start",xs:'center'}
                    }} >
                        {content.p}
                    </Typography>


                </Box>
            </Box>


            <Box m={{xs:4,md:10}} sx={{direction:direction}}>
                
                <Container  >
                <Typography data-aos="fade-left" align="justify" className='px-md-5 py-5'  sx={{fontSize:'18px'}} color={'#777'} >
                   {content.p2}
                   <Link to="/about">
                    
                       
                        {
                            lang === 'en' ?  'Read More': 'اقرأ المزيد'
                        }
                    
                    </Link>

                </Typography></Container>
                

                <Box  >
                    <img src={img1}  data-aos="zoom-in" alt="" className='col-12 mb-1 col-md-11'  />
                </Box>
            </Box>
            <Newres />
            <br /><br /><br /><br /><br />


        </Box>
    );
}

export default Home;




const en = {
    h: 'Our Vision',
    p: `Create positive social and economic impact by elevating Iraqi market level and 
    experience.`,
    p2:`Shubber Group is a dynamic family-owned Iraqi enterprise, first established in 1992. With a consistent record of growth and innovation, we are one of Iraq’s leading brand Distributor and operator, offering an unparalleled choice of well-loved international brands to customers. Our portfolio extends across all Iraq (Kurdistan, Baghdad, western region, and southern region) Operating in multiple sectors including Health & Pharmacy and food and beverage sector. We implemented a unique business model based on creating a highly collaborative partnership that facilitates a win-win outcome in which both Shubber and our partners are equally committed to each other’s success. A collaborative Supplier-Distributor partnership means that Shubber objective is to ensure the success of all Supplier, with high retention rate & long-lasting relationship.`

    
}


const ar = {
    h: 'رؤيتنا',
    p: `خلق تأثير اجتماعي واقتصادي إيجابي من خلال رفع مستوى الاسواق العراقية وتجربتها.`,
    p2:`
    مجموعة شُبر هي مؤسسة عراقية عائلية ديناميكية ، تأسست في عام 1992. مع سجل ثابت من النمو والابتكار ، نحن أحد موزعي ومشغلين العلامات التجارية الرائدة في العراق ، حيث نقدم خيارا لا مثيل له من العلامات التجارية العالمية المحبوبة للعملاء. تمتد شركتنا في جميع أنحاء العراق (كردستان وبغداد والمنطقة الغربية والمنطقة الجنوبية) نعمل في قطاعات متعددة بما في ذلك الصحة والصيدلة وقطاع الأغذية والمشروبات ، لقد قمنا بتنفيذ نموذج أعمال فريد يعتمد على إنشاء شراكة تعاونية تسهل تحقيق نتيجة مربحة للجانبين يلتزم فيها كل من شُبر وشركائنا بنفس القدر بنجاح بعضهم البعض
    
    .`
   }

export const date = { en, ar }
