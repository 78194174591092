/* eslint-disable no-func-assign */

import './App.css';
import Nav from './components/navbar/nav'
import Home from './components/layout/home/home';
import Contact from './components/layout/contact/contact';
import About from './components/layout/about/about';
import Gallery from './components/layout/gallery/gallery';
import Partners from './components/layout/partners/partners';
import Career from './components/layout/career/career';
import Aos from 'aos';
import 'aos/dist/aos.css';
import arowicon from './assets/arowicon.png';
import Loder from './components/loder/loder';
import React from 'react';
import Footer from './components/footer/footer';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

export const Contx = React.createContext();


Aos.init({ duration: 2000 });





function App() {


  const [lang, setLang] = React.useState('en');
  const [loder, setLoder] = React.useState(true);
  const [path] = React.useState(window.location.pathname);
  
  React.useEffect(() => {
    setTimeout(() => {
      setLoder(false)
    }, 5000);
  }, [])

 
  React.useEffect(() => {
    
   

    window.scrollTo(0, 0);
  }, [path]);


  return (
    <>
      {
        loder ? <Loder /> : <Contx.Provider value={{ lang, setLang }}>
          <div className="App">


            <BrowserRouter>
              <Nav />
              <ScrollToTop />
              <Routes>


                <Route path="/" element={<Home />} />

                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/Careers" element={<Career />} />




              </Routes>

              <Footer />
            </BrowserRouter>


            <ScrollToTop />
          </div>
        </Contx.Provider>
      }
    </>);
}

export default App;






function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



ScrollToTop = () => {



  return (
    <div >
      <a href=" #" className="scrollup" style={{ display: 'inline' }}>Scroll</a>


      <style jsx>{`
      .scrollup {
        position: fixed;
        bottom: 20px;
        right: 20px;
        display: none;
        width: 50px;
        height: 50px;
        text-indent: -9999px;
        background: url(${arowicon}) no-repeat;
        background-size: contain;
        z-index: 999;
        border-radius: 50%;
        transition: background .5s ease-in-out;
      }
      .scrollup:hover {
       filter: grayscale(100%);
      }
      .scrollup.show {
        display: block;
      }
      `}</style>




    </div>
  )
}


