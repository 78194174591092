import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { Contx } from '../../App'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';



const Header = () => {
    const { lang ,setLang} = React.useContext(Contx);
    const handllang = () => {
        lang === 'en' ? setLang( 'ar') : setLang( 'en')
        
    }

    const icon = [LinkedInIcon, InstagramIcon, FacebookIcon, TwitterIcon]
    return (
        <Box sx={{ background: 'linear-gradient(91deg, #1474B0 0%, #688C3C 100%)', display: 'flex', flexDirection: 'row-reverse' }}   >
            <Button onClick={handllang}>
                <Typography variant="h5" color="white" align='left' p={1}  >
                    {lang === 'en' ? 'عربي' : 'English'}
                </Typography>

                <hr />

            </Button >

            

           
        </Box >
    );
}

export default Header;
