import React from 'react';
import { Box ,Typography} from '@mui/material';
import { Contx } from '../../../App';



const Gratitude = () => {
    const {lang} = React.useContext(Contx)
    const content = lang ==='ar'? data.ar : data.en
    const direct = lang ==='ar'?'right':'left'
    return (
        <Box mt={5} data-aos="fade-up"
        data-aos-anchor-placement="bottom">
           <Typography variant='h4' gutterBottom component="div" sx={{color:'#1e3c72',fontWeight:'bold',textAlign:direct,marginBottom:'20px',fontSize:'20px'}}>
              {content.h}
            </Typography>
            <Box sx={{textAlign:'left',marginBottom:'20px',direction:lang==='ar'?'rtl':'ltr'}}>
            {content.list.map((item,i)=><Typography variant="h6" gutterBottom component="div" sx={{color:'#777',textAlign:direct,marginBottom:'20px',fontSize:'18px'}}>
                 {i+1}-{' '+item}
            </Typography>)}
            </Box>
            {
                content.p.map((item,i)=><Typography variant="h6" gutterBottom component="div" sx={{color:'#555',textAlign:direct,marginBottom:'20px'}}>
                {item}
            </Typography>)
            }
        </Box>


    );
}

export default Gratitude;


const en = {
    h:`We would like to express our gratitude to our partners for:`,
    list: [
        `Collaboration and Innovation: Your willingness to collaborate, share ideas, and co-create solutions has been instrumental in driving innovation and pushing the boundaries of what we can achieve together. Our combined efforts have led to breakthrough products/services and delivered exceptional value to our customers.`,
        `Expertise and Support: Your specialized knowledge, skills, and experience have greatly enriched our capabilities. Your support and guidance have been invaluable in navigating complex market dynamics and addressing the evolving needs of our customers.`,
        `Trust and Reliability: The trust we have built with our partners is the foundation of our shared success. We appreciate your reliability, integrity, and commitment to delivering on promises. Together, we have built a reputation for excellence and customer satisfaction.`,
        `Mutual Growth and Success: Our partnerships are not merely transactional; they are true collaborations focused on mutual growth and success. We celebrate the milestones we have achieved together and remain dedicated to exploring new opportunities and expanding our horizons.`,
    ],

    p:[
         `Thank you for being a valued partner of shubber Group. Together, we will continue to shape a brighter future.`,
    ]
}


const ar = {
    h:` : نود أن نعبر عن امتناننا لشركائنا على`,
    list: [
        `.التعاون والابتكار: لقد كان استعدادك للتعاون ومشاركة الأفكار والتعاون في إيجاد حلول أمرًا حاسمًا في دفع الابتكار ودفع حدود ما يمكننا تحقيقه معًا. لقد أدت جهودنا المشتركة إلى منتجات / خدمات مبتكرة وتقديم قيمة استثنائية لعملائنا`,
        `.الخبرة والدعم: لقد أثرت معرفتك ومهاراتك وخبرتك المتخصصة بشكل كبير على قدراتنا. لقد كان دعمك وتوجيهك لا غنى عنه في التنقل في ديناميكيات السوق المعقدة ومعالجة الاحتياجات المتطورة لعملائنا`,
        `.الثقة والموثوقية: الثقة التي بنيناها مع شركائنا هي أساس نجاحنا المشترك. نحن نقدر موثوقيتك ونزاهتك والتزامك بتحقيق الوعود. معًا ، بنينا سمعة للتميز ورضا العملاء`,
        `.النمو المتبادل والنجاح: شراكاتنا ليست مجرد معاملات ؛ إنها تعاون حقيقي يركز على النمو والنجاح المتبادل. نحتفل بالمعالم التي حققناها معًا ونبقى ملتزمين باستكشاف فرص جديدة وتوسيع آفاقنا`,
    ],

    p:[
         `.شكرا لك على كونك شريكًا مقدرًا لمجموعة شبر. معًا ، سنستمر في صياغة مستقبل أكثر إشراقًا`,
    ]
}

export const data = {ar,en}