import React from 'react';
import { Box, Container, Grid, Typography, TextField, Alert, AlertTitle, Button } from '@mui/material';
import img from './1.png'
import { Link } from 'react-router-dom';
import { Contx } from '../../../App'
import emailjs from 'emailjs-com';



const Career = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? en : ar
    const dir = lang === 'en' ? 'ltr' : 'rtl'

    const [show, setShow] = React.useState(true)

    const sendemail = (e) => {
        e.preventDefault();




        emailjs.sendForm('service_6w62c43', 'template_2x6i8bu', e.target, 'vQ9tfUhCEgG94XuCk')
            .then((result) => {
                console.log(result.text);
                setShow(false)

                setTimeout(() => {
                    setShow(true)
                    e.target.reset()
                }, 5000);



            })
            .catch((error) => {
                console.log(error.text);
            });

    }









React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Box pt={7}>
            <Container sx={{ direction: dir }} >
                <Grid container spacing={0} >
                    <Grid item xs={12} md={6} >
                        <Box sx={{ textAlign: 'start', m: 5 }} >
                            <img src={img} loading='lazy' alt="" width={'100%'}/>

                            <Typography variant='h5' sx={{fontSize:'18px', mb: 2, color: '#1675AE' }} >
                                {content.p1}
                            </Typography>
                            <Typography variant='h5' sx={{ fontSize:'18px', mb: 2, color: '#1675AE' }} >
                                {content.p2}:
                                <Link to={'mailto: careers@alshubbergroup.com'} style={{fontSize:'18px', textDecoration: 'none', color: '#688C3E' }} >
                                    careers@alshubbergroup.com
                                </Link>
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} direction={dir} >
                        <Box sx={{ textAlign: 'start', m: 5 }}  >

                            <Alert severity="success" hidden={show} sx={{ m: 5, mt: 0 }}>
                                <AlertTitle>Success</AlertTitle>
                                Your message has been sent successfully
                            </Alert>


                           



                            <form  action='https://formspree.io/f/xjvqeerq' method='POST' > 
                            <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2, color: '#1675AE' }} >
                                Apply Now
                            </Typography>


                                <TextField sx={{ width: '100%', my: 1 }} name='from_name' label="Name" variant="outlined" />
                                <TextField sx={{ width: '100%', my: 1 }} name='from_address' label="Address" variant="outlined" />
                                <TextField sx={{ width: '100%', my: 1 }} name='from_phone' label="Phone Number" variant="outlined" />

                                <TextField multiline maxRows={4} rows={4} sx={{ width: '100%', my: 1 }} label="Message" variant="outlined" name='message' />
                                <Grid container spacing={2} >
                                    <Grid item xs={12}  >
                                        <Button variant="contained" component="label" style={{textTransform: 'none'}} > Attach CV
                                            <input type="file" hidden />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}  >
                                        <input type="submit" value={'Send'} style={{ backgroundColor: '#1575B0', color: '#FFF' }} />
                                    </Grid>
                                </Grid>

                            </form>


                        </Box>
                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}

export default Career;


const en = {
    p1: ` We offer a rewarding range of career opportunities
    for talented people who want to contribute to the
    development of our industry and achieve their potential.`,

    p2: `If you think you have what it takes to make a contribution to our global success, please contact us at`,


}



const ar = {
    p1: `نحن نقدم مجموعة متنوعة من الفرص الوظيفية المجزية
    للأشخاص الموهوبين الذين يرغبون في المساهمة في
    تطوير صناعتنا وتحقيق إمكاناتهم.`,
    p2: `إذا كنت تعتقد أن لديك ما يلزم للمساهمة في نجاحنا العالمي ، فيرجى الاتصال بنا على`,
}