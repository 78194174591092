import React from 'react';
import { Contx } from '../../../App';
import { Box, Container, Typography } from '@mui/material';
import img from './backimg.png'

const Ethics = () => {
    const {lang} = React.useContext(Contx)
    const data = lang === 'ar'? ar : en
    const dir = lang==='en'?'ltr':'rtl'

    return (
        <Box py={5} px={{md:5}} sx={{position:'relative'}} data-aos="fade-up"
        data-aos-anchor-placement="bottom">
             
            <Container sx={{ direction: dir }} px={{md:10}}>
               <img src={img} alt="" style={{
                    position: 'absolute',
                    width: '90%',
                    top: '20%',
                    right: '2%',
                    opacity: '0.3',
                    
                   
                    zIndex: '-1',
                }} />
                {
                    data.header.map((item, i) => {
                        return (
                            <Typography variant='h6' sx={{ textAlign:{md:'justify',xs:'center'}, my: 5, color: '#555',fontWeight:'samebold' }} >
                                {item}
                            </Typography>
                        )
                    })
                }
                <Typography variant='h4' sx={{ textAlign:{md:'justify',xs:'center'}, mb: 2, color: '#1474B0',ontWeight:'bold'  }} >
                    {data.h}
                </Typography>
                {
                    data.list.map((item, i) => {
                        return (
                            <Box sx={{ mb: 2 }}>
                                <Typography variant='h6' sx={{ textAlign:{md:'justify',xs:'center'}, mb: 1, color: '#688C3C',fontWeight:'bold' }} >

                                    {item.h}
                                </Typography>
                                <Typography variant='h6' sx={{ textAlign:{md:'justify',xs:'center'}, mb: 4, color: '#555' }} >
                                
                                    {item.p}
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Container>
        </Box>
    );
}

export default Ethics;


const en = {
    header: [
        `At Shubber group, we firmly believe that conducting business with the utmost ethics and integrity is not only a responsibility but also a cornerstone of our success. We are dedicated to upholding a strong ethical culture that guides our actions, decisions, and interactions with all our partners.`,
       
    ],


    h: 'Our ethical principles include:',

    list: [
        {h:`Honesty and Transparency:`,p:` We communicate openly, honestly, and transparently with our employees, customers, partners, and shareholders. We provide accurate and timely information and ensure that our actions align with our words.`},
        {h:`Respect and Fairness:`,p:` We treat everyone with respect, fairness, and dignity. Discrimination, harassment, or any form of unfair treatment is strictly prohibited. We embrace diversity and foster an inclusive environment where everyone has an equal opportunity to succeed.`},
        {h:`Compliance with Laws and Regulations:`,p:` We comply with all applicable laws, regulations, and industry standards. We are committed to maintaining a comprehensive understanding of legal requirements and conducting our business in full compliance with them.`},
        {h:`Protection of Confidentiality and Privacy:`,p:` We safeguard the confidential and personal information entrusted to us. We respect the privacy of our employees, customers, and partners, and handle their information securely and responsibly.`},
        {h:`Responsible Business Practices:`,p:` We conduct our business in a socially and environmentally responsible manner. We strive to minimize our impact on the environment, promote sustainability, and support initiatives that benefit our communities.`},
       
    ]

}


const ar = {
    header: [
        'في مجموعة شبر، نحن نؤمن إيمانا راسخا بأن ممارسة الأعمال التجارية بأقصى قدر من الأخلاق والنزاهة ليست مسؤولية فحسب، بل هي أيضا حجر الزاوية في نجاحنا. نحن ملتزمون بدعم ثقافة أخلاقية قوية توجه أعمالنا وقراراتنا وتفاعلاتنا مع جميع شركائنا.',
       
    ],


    h: `تشمل مبادئنا الأخلاقية ما يلي:`,

    list: [

        {h:` الصدق والشفافية :`,p:` نتواصل بصراحة وصدق وشفافية مع موظفينا وعملائنا وشركائنا ومساهمينا. نحن نقدم معلومات دقيقة وفي الوقت المناسب ونضمن أن أفعالنا تتماشى مع كلماتنا.`},
        {h:`الاحترام والإنصاف:`,p:` نتعامل مع الجميع باحترام وإنصاف وكرامة. يمنع منعا باتا التمييز أو المضايقة أو أي شكل من أشكال المعاملة غير العادلة. نحن نحتضن التنوع ونعزز بيئة شاملة حيث يتمتع الجميع بفرصة متساوية للنجاح.`},
        {h:`الامتثال للقوانين واللوائح:`,p:` نحن نلتزم بجميع القوانين واللوائح المعمول بها ومعايير الصناعة. نحن ملتزمون بالحفاظ على فهم شامل للمتطلبات القانونية وإجراء أعمالنا في الامتثال الكامل لها.`},
        {h:` حماية السرية والخصوصية:`,p:`نحن نحمي المعلومات السرية والشخصية الموكلة إلينا. نحن نحترم خصوصية موظفينا وعملائنا وشركائنا ، ونتعامل مع معلوماتهم بشكل آمن ومسؤول.`},
        {h:` الممارسات التجارية المسؤولة:`,p:`ندير أعمالنا بطريقة مسؤولة اجتماعيا وبيئيا. نسعى جاهدين لتقليل تأثيرنا على البيئة ، وتعزيز الاستدامة ، ودعم المبادرات التي تفيد مجتمعاتنا.`},
      
      ]

}
