import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';


import { Contx } from '../../../App'


const Value = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? data.en : data.ar
    const dir = lang === 'en' ? 'ltr' : 'rtl'
    const bgcolor = ['#00799F', '#006289', '#00557A', '#004664']
    return (
        <Box data-aos="fade-up"
        data-aos-anchor-placement="bottom">


            <Container sx={{ direction: dir }} >
                <Typography variant="h3" align="center"   fontWeight={'bold'} p={5} color={'#000'} >
                    
                    {lang === 'en' ? 'Our Values' : 'قيمنا'}
                </Typography>

                <Grid container spacing={0} sx={{ direction: dir }} >
                    {
                        content.map((item, i) => {
                            return (
                                <Grid item xs={12} md={6} lg={3} >
                                    <Box sx={{ textAlign: 'center',bgcolor:bgcolor[i] ,height:'390px'}} p={3} py={7}>
                                        <Typography variant="h5" align="center"  style={{minHeight:'100px',fontWeight:'bolder'}}  color={'#fff'} >
                                            {item.h}
                                        </Typography>
                                        <Typography variant="p" align="center" fontSize={'16px'} color={'#fff'} >
                                            {item.p}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>

           





           
        </Box>
    );
}

export default Value;





const en = [
    
    {
        h: 'Customer-Oriented',
        p: `Prioritizing satisfaction of our customers,
                treating them as part of our extended
                family. We strive to understand their
                needs and build long-lasting relationships.`
    },
    {
        h: 'High Quality and Standards',
        p: `We maintain a strong commitment to
                delivering products and services of the
                highest quality. We adhere to international standards within our presented services
                and internal processes.`
    },
    {
        h: 'Professionalism and Ethical Practices',
        p: `We conduct our business with professionalism and integrity. Maintaining transparency, honesty, and ethical practices in all
                our interactions and decision-making processes.`
    },
    {
        h: 'Sustainability',
        p: `We are dedicated to sustainable practices
                that minimize our environmental impact
                and prioritize environmentally friendly
                initiatives, and responsible waste management`
    },
   
]



const ar = [
   
    {
        h: '  موجهة للعملاء',
        p: `الأولوية لرضا عملائنا ، معاملتهم كجزء من عائلتنا الموسعة. نحن نسعى لفهم احتياجاتهم وبناء علاقات طويلة الأمد.`
    },
    {
        h: 'جودة عالية ومعايير',
        p: `نحن نحافظ على التزام قوي بتقديم منتجات وخدمات عالية الجودة. نلتزم بالمعايير الدولية في الخدمات التي نقدمها والعمليات الداخلية.`
    },
    {
        h: 'المهنية والممارسات الأخلاقية',
        p: `نقوم بأعمالنا بمهنية ونزاهة. الحفاظ على الشفافية والصدق والممارسات الأخلاقية في جميع تفاعلاتنا وعمليات صنع القرار.`
    },
   
    {
        h: 'الاستدامة',
        p: `نحن ملتزمون بالممارسات المستدامة التي تقلل من تأثيرنا البيئي وتعطي الأولوية للمبادرات الصديقة للبيئة وإدارة النفايات المسؤولة`
    },

   
]

const data = { en, ar }