import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Contx } from '../../../App'
import ceo from './ceo.jpg'
import bkimg from './backimg.png'





const Message = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? en : ar
    const dir = lang === 'en' ? 'ltr' : 'rtl'

    return (
        <Box data-aos="fade-up"
        data-aos-anchor-placement="bottom">
            <Container sx={{ direction: dir }} >
                <Box >

        
                <Grid container  m={{md:5}}  sx={{
                        background: `url(${bkimg}) `,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right ',
                        backgroundSize: '55vw',
                        backgroundAttachment:"scroll",
                    }}>
                    <Grid item xs={12} md={3}>

                        <img src={ceo} alt=""   style={{
                            width: '100%',
                        }} />

                        <p style={{
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            scale: '1.5',
                            paddingTop: '10px',
                        }}>
                            Hanan Hashim  Shubber <br />
                            Founder & CEO
                        </p>






                    </Grid>
                    <Grid item md={9} xs={12} my={2} >


                        {
                            content.p.map((item, i) => {
                                return (
                                    <Typography variant='h6' sx={{ textAlign: "justify", mb: 2, color: '#555', fontWeight: 'samebold', fontSize: '16px' }} >
                                        {
                                            item
                                        }
                                    </Typography>
                                )
                            })
                        }

                        <Typography variant='h6' sx={{ textAlign: "justify", mb: 2, color: '#555', fontWeight: 'samebold', fontSize: '16px' }} >

                           
                           {
                                 content.footer
                           }
                        </Typography>
                        <Typography variant='h6' sx={{ textAlign: "justify", mb: 2, color: '#555', fontWeight: 'samebold', fontSize: '16px' }} >

                           
                           {
                                 content.ceo
                           }
                        </Typography>
                           
                    </Grid> </Grid>


                    </Box>
               
            </Container>
        </Box>

    );
}

export default Message;



const en = {
    ceo:'Your CEO',
    footer: `With utmost admiration,`,
    p: [
        `It took decades of hard work, and determination from Shubber’s team to become one of Iraq’s biggest international companies.`,
        `A commitment to diversity and gender equality usually starts at the top of an organization, and we as Shubber’s group is pleased that we are recognized for the fact that our board is 40 percent female. And we know there is more to be done.`,
        `In an ever evolving and highly competitive landscape, your prowess in driving organic traffic and developing effective strategies, your skills, dedication, and innovation have played a pivotal role in helping our company thrive in the region.`,

        `Remember, your unique perspective and insights are invaluable. Embrace your strengths, harness your creativity, and never doubt the impact you can make. Break through the glass ceiling, challenge stereotypes, and lead the way towards a more inclusive and diverse community.`,
        `To all Shubber’s employees, I commend you for your dedication, expertise, and passion. Your contributions continue to inspire and drive myself and the industry forward. Keep shining brightly and never underestimate the impact of your voice.`,
        `Together, let us pave the way for a future where we all are celebrated, respected, and recognized for our exceptional achievements.`,
    ]
}


const ar = {
    ceo:'الرئيس التنفيذي',
    footer: `مع أطيب التحيات ،
                `,
    p: [
        `لقد استغرق الأمر عقودًا من العمل الشاق والإصرار من فريق شبر ليصبح واحدًا من أكبر الشركات الدولية في العراق.`,
        `عادة ما تبدأ التزامات التنوع والمساواة بين الجنسين من الأعلى في المنظمة ، ونحن كمجموعة شبر نسعد بأننا معترف بها لأن مجلس إدارتنا يضم 40 في المائة من الإناث. ونحن نعلم أن هناك المزيد للقيام به.`,
        `في مناظر طبيعية متطورة ومنافسة للغاية ، فإن براعتك في دفع حركة المرور العضوية وتطوير استراتيجيات فعالة ، ومهاراتك وتفانيك وابتكارك لعبت دورًا حاسمًا في مساعدة شركتنا على الازدهار في المنطقة.`,

        `تذكر ، إن منظورك ورؤيتك الفريدة لا تقدر بثمن. اعتنق قوتك ، واستثمر في إبداعك ، ولا تشك في الأثر الذي يمكنك تحقيقه. اخترق السقف الزجاجي ، وتحدى الصور النمطية ، وقدم الطريق نحو مجتمع أكثر شمولًا وتنوعًا.`,
        `إلى جميع موظفي شُبر، أود أن أشيد بكم على التفاني والخبرة والشغف الذي تبدنونه. إسهاماتكم مستمرة في إلهامي ودفعي، وتسهم في دفع صناعتنا للأمام. استمروا في التألق ولا تستهينوا أبدًا بتأثير أصواتكم. معًا، دعونا نفتح الطريق لمستقبل يتم فيه الاحتفال بالجميع واحترامهم والاعتراف بإنجازاتهم الاستثنائية.`,
    ]
}