import React from 'react';

import { Box, Container } from '@mui/material';

import Galaryslide from './galaryslide/sliderser';

const App = () => {


  React.useEffect(() => {
    window.scrollTo(0, 0)
}, [])

return <Box m={4}>
  
  <Container  >
    <Galaryslide/>
  </Container>
</Box>;}
export default App;