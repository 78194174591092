import React from 'react';
import logo from '../../logo.svg'

const Loder = () => {
    return (
        <div  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            <img className='loder' src={logo} alt="" width={'50%'}/>
        </div>
    );
}

export default Loder;
