import React from 'react';
import { Contx } from '../../../App';
import { Box, Typography, Container } from '@mui/material'
import img from './par.png'

const Partnertap = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? data.en : data.ar
    const [state, setstate] = React.useState(true)

    const handelshow = () => {
        setstate(!state)
       
    }

    return (
        <Container>
        <Box mb={10} mt={5} sx={{ width: '100%', direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
            {
                content.map((item, index) => <p key={index} data-aos="fade-up"
                data-aos-anchor-placement="bottom" style={{ textAlign: 'justify', fontSize: '18px', lineHeight: '2rem', color: '#1976A9' }}>{item}</p>)

            }

            <Box sx={{ mt: 9 }}>
                <h3 style={{
                    textAlign: 'left',
                }}>
                            SECTERS:
                        </h3>

                        <br />

               
                        <Box  style={{ textDecoration: 'none' ,maxWidth:'300px'}}>
                        <a href="https://shubbersb.com/partners/" style={{ textDecoration: 'none' ,maxWidth:'300px' }} >

                            <Typography variant="h5"  align='center' pl={{ md: 0 }} sx={{
                                
                                py:2,
                                border: '1px solid #688C3C', 
                                borderRadius: '10px',
                                backgroundColor: '#1474B0',
                                    color: '#fff',
                              
                                
                            }}>
                                Pharmaceutical
                            </Typography>
                        </a></Box>

                    <br />
                    
                    
                        <Box  style={{ textDecoration: 'none' ,maxWidth:'300px'}} onClick={handelshow} >
                            <Typography variant="h5" color="#688C3C" align='center'  sx={{
                                
                                py:2,
                                border: '1px solid #688C3C', 
                                borderRadius: '10px',
                                backgroundColor: '#688C3C',
                                    color: '#fff',
                              
                                    
                            }}>
                            Food and beverage
                        </Typography></Box>

                    
               



            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
               <a href="https://instagram.com/faroojrepublic.iq?igshid=MzRlODBiNWFlZA=="> <img src={img} hidden={state}  alt="" /></a>
            </Box>

        </Box></Container>
    );
}

export default Partnertap;


const en = [


    `We are excited to take a moment to recognize and appreciate the invaluable partnerships that contribute to 
our success at Shuber group. Our partners play a crucial role in helping us achieve our goals, deliver exceptional products/services, and create lasting impact in the marketplace.`,
    `At Shubber, we believe that strong partnerships are built on shared values, trust, and mutual growth. We are 
    grateful for the collaborative relationships we have formed with our esteemed partners, and we remain committed to nurturing and strengthening these connections.`,
    `Our partners bring diverse expertise, resources, and perspectives that complement our own, allowing us to 
    enhance our offerings and expand our reach. Together, we have achieved remarkable milestones and overcome challenges, and we look forward to many more shared successes in the future.`

]


const ar = [
    `نحن متحمسون لأخذ لحظة للاعتراف وتقدير الشراكات اللا تقدر بثمن التي تساهم في نجاحنا في مجموعة شوبر. تلعب شركاؤنا دورًا حاسمًا في مساعدتنا على تحقيق أهدافنا وتقديم منتجات / خدمات استثنائية وخلق تأثير دائم في السوق.`,
    `في شوبر ، نعتقد أن الشراكات القوية تبنى على القيم المشتركة والثقة والنمو المتبادل. نحن ممتنون للعلاقات التعاونية التي شكلناها مع شركائنا الموقرين ، ونحن ملتزمون بالحفاظ على هذه العلاقات وتعزيزها.`,
    `يجلب شركاؤنا خبرات وموارد وآراء متنوعة تكمل خبرتنا الخاصة ، مما يتيح لنا تعزيز عروضنا وتوسيع نطاقنا. معًا ، حققنا إنجازات ملحوظة وتغلبنا على التحديات ، ونتطلع إلى المزيد من النجاحات المشتركة في المستقبل.`
]

export const data = { en, ar }