import { Box, Container, Grid, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import logo from '../../logo1.svg'
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link, NavLink } from 'react-router-dom';
import icon from './1.svg'
import { Directions } from '@mui/icons-material';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: '#1474B0' }} >
            <Container >
                <Box sx={{ color: 'white', display: 'flex', justifyContent: 'center', p: 2 }}>






                    {/* <Grid container style={{ direction: 'rtl' }} >

                        <Grid item xs={4} >
                            <Typography variant="h5" color="white" align='left' pl={4} mt={2} style={{ direction: 'ltr' }} >

                                Contact Us:
                            </Typography>
                            <a href="tel:+9647809190218" style={{ textDecoration: 'none' }}>
                                <Typography variant="h6" color="white" align='left' pl={4} mt={2} style={{ direction: 'ltr' }} >

                                    <SvgIcon component={CallIcon} sx={{ fontSize: '17px', color: 'white' }} />  +964 780 919 0218
                                </Typography>
                            </a>
                            <a href="mailto:info@alshubbergroup.com" style={{ textDecoration: 'none' }}>
                                <Typography variant="h6" color="white" align='left' pl={4} style={{ direction: 'ltr' }}>
                                    <SvgIcon component={MailOutlineIcon} sx={{ fontSize: '20px', mr: 1, color: 'white' }} />
                                    info@alshubbergroup.com
                                </Typography>
                            </a>

                        </Grid>


                    </Grid> */}







                    <Grid container  >
                        <Grid item xs={12}  >
                            <img src={logo} alt="" width={150} />

                        </Grid>
                        <hr style={{ width: '100%', color: '#000' }} />
                        <Grid container  >
                            <Grid item xs={12} md={4} >

                                <NavLink to={'/'} style={{ textDecoration: 'none', color: '#fff' }} >
                                    Home
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <NavLink to={'/About'} style={{ textDecoration: 'none', color: '#fff' }} >
                                    Who We Are
                                </NavLink>

                            </Grid>
                            <Grid item xs={12} md={4} >
                            <NavLink to={'/Contact'} style={{ textDecoration: 'none', color: '#fff' }} >
                            Contact Us
                                </NavLink>

                               
                            </Grid>
                            <Grid item xs={12}  >

                                <Typography variant="body" color="#222" align='center' pl={4} mt={4} style={{ direction: 'ltr', fontSize: '12px' }} >
                                    2023 © Alshubber Group | ALL RIGHTS RESERVED

                                </Typography>
                            </Grid>

                        </Grid>




                    </Grid>



                </Box>

            </Container></Box>
    );
}

export default Footer;
