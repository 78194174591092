import React from 'react';
import { Box, Container,  Typography } from '@mui/material';
import { Contx } from '../../../App'
import bkimg from './peback.jpg'


const People = () => {
    const { lang } = React.useContext(Contx)
    const content = lang === 'en' ? en : ar
    const dir = lang === 'en' ? 'ltr' : 'rtl'

    return (
        <Box p={{md:30}} py={5} pb={5} sx={{backgroundColor:'#F5F5F5',zIndex:'0'}} data-aos="fade-up"
        data-aos-anchor-placement="bottom">
            <img src={bkimg} alt="" style={{
                position: 'absolute',
                width: '100%',
                top: '0%',
                right: '0%',
                opacity: '0.85',
                zIndex: '0.1',


            }}/>
            <Container sx={{ direction: dir }}>
                
                {
                    content.map((item, i) => {
                        return (
                            
                                        <Typography variant='h6' sx={{ textAlign:'justify', mb: 2, color: '#555' }} >
                                            {item}
                                        </Typography>
                                    
                        )
                    })

                }
            </Container>
        </Box>
    );
}

export default People;



const en = [
    `The passion and commitment of our group is the reason why our business partners trust us to protect and grow their brands.`,
    `Our success is based on the contribution of 1,000 skilled colleagues, Trainers, Warehouse teams, Brand teams, and Expert support teams. Everyone contributes to creating a great customer experience and driving our business forward.`,
    `Representing multiple nationalities, the breadth of perspectives from our multi-cultural, diverse workforce nurtures a culture of support, collaboration, and openness. Our management community has deep expertise and knowledge gained with some of the leading brand names in their industries. Our values – ‘think big, act small, be you’ - affirm our commitment to always act with trust and integrity.`,
    `We strive always to make Shubber ‘the best place to work’ and continually invest in people, both to drive performance, and to support their personal growth, development, and well-being. From structured learning opportunities to regular employee surveys and transparent communications, we are committed to a culture that attracts and retains the very best.

    `,
]

const ar = [
    `الشغف والالتزام لدى مجموعتنا هو السبب في ثقة شركائنا التجاريين بنا لحماية وتنمية علاماتهم التجارية.`,
    `يستند نجاحنا إلى مساهمة 1000 من زملائنا الماهرين ، وفرق المدربين ، والفرق اللوجستية ، وفرق العلامات التجارية ، وفرق الدعم المتخصصة. يساهم الجميع في خلق تجربة عملاء رائعة ودفع أعمالنا قدمًا.`,
    `من خلال تمثيل جنسيات متعددة ، فإن اتساع وجهات النظر من قوتنا العاملة متعددة الثقافات والمتنوعة يغذي ثقافة الدعم والتعاون والانفتاح. يتمتع مجتمع الإدارة لدينا بخبرة عميقة ومعرفة مكتسبة مع بعض الأسماء التجارية الرائدة في صناعاتهم. تؤكد قيمنا - "فكر بشكل كبير ، تصرف صغيرا ، كن أنت" - التزامنا بالعمل دائما بثقة ونزاهة.`,
    `نحن نسعى دائما لجعل شبر أفضل مكان للعمل" ونستثمر باستمرار في الأشخاص ، لدفع الأداء ودعم نموهم الشخصي وتطورهم ورفاهيتهم. تبدء من فرص التعلم المنظمة إلى استطلاعات الموظفين المنتظمة والاتصالات الشفافة ، نحن ملتزمون بثقافة تجذب الأفضل وتحتفظ به.`,
]



