import React from 'react';
import { Tabs } from 'antd';
import { Box, Container } from '@mui/material';
import Value from './value';
import People from './people';
import Message from './message';
import Ethics from './ethics';

import { Contx } from '../../../App';






const onChange = (key) => {
  console.log(key);
};





const App = () => {

  const {lang} = React.useContext(Contx);

  const data = lang === 'en' ? [`Our Values`,`Our People`,`CEO Message`,`Company ethics `,`company Diversity & Inclusion message`] : [`قيمنا`,`موظفينا`,`رسالة المدير التنفيذي`,` أخلاقيات الشركة`,`رسالة التنوع والاندماج للشركة`]

  const items = [
    {
      key: '1',
      label: data[2],
      children: <Message />,
    },
    {
      key: '2',
      label: data[0],
      children: <Value />,
    },
    {
      key: '3',
      label: data[1],
      children: <People />,
    },
    {
      key: '4',
      label: data[3],
      children: <Ethics />,
    }, 
    // {
    //   key: '5',
    //   label:  data[4],
    //   children: <Inclusion />,
    // },
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0)
}, [])
 return <Box m={{md:5,xs:3}}>
  <Container  >

    <Tabs defaultActiveKey="1" items={items} centered  onChange={onChange} size='small' type="card"/>
</Container>
</Box>}
export default App;