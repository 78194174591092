import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import baghdad from './baghdad.png'
import { Contx } from '../../../App'


export const Boxres = (props) => {

    const boxStyles = {
        opacity: '0.7',
        height: 'stretch',
       

        background: 'linear-gradient(white, white) padding-box,linear-gradient(90deg, #1474B0, #688C3C) border-box',
        borderRadius: '15px',
        border: '4px solid transparent',

       
    
       
      };
    return (
        <Box  >
            <Box p={2} py={5} sx={boxStyles}>
                <Typography  align="center"   style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingBottom: '10px',
                }} >
                    {props.h}
                </Typography>
                <Typography  align="justify"  style={{
                    fontSize: '16px'
                }} >
                    {props.p}
                </Typography>
            </Box>
        </Box>
    )

}


const Newres = () => {

    const { lang } = React.useContext(Contx)

    const content = lang === 'en' ? date.en : date.ar
 


    return (
        <Box>

            <Container>
                <img src={baghdad} alt="" width={'100%'} />
                <Typography align="center" sx={{mt:{md:0},m:{xs:10}}} style={{
                    fontSize: '35px',
                    fontWeight: 'bold',
                    color:'#777',
                    transform: 'translateY(-50px)',
                    

                }}>
                    {content.h}

                </Typography>
                <Grid container spacing={7}  data-aos={'fade-up'} data-aos-anchor-placement="center" >
                    

                    {
                        content.list.map((item, i) => {
                            return (
                                <Grid item xs={12} md={4}  >
                                    <Boxres h={item.h} p={item.p} />
                                </Grid>
                            )
                        }
                        )
                    }
                    
                   
                   
                </Grid>
            </Container>

        </Box>
    );
}

export default Newres;







const en = {
    h: 'Our approach to business',
    list: [
        {
            h: 'A commitment to our people',
            p: `We run a range of programs designed 
            to develop, empower, reward, and recognize our people, and to ensure they 
            enjoy a good work life balance. The 
            Shubber Leadership Program. Great 
            benefits. Flexible working. An active 
            Sport & Social Club. That’s just some of 
            the things we do to ensure a vibrant 
            and healthy culture at Shubber.`
        },
       
        {
            h: 'A commitment to where we live',
            p: `As a leading employer in this sector, 
            there are many ways we can make a 
            sustainable difference to the communities in which we operate. Jobs creation 
            and skills development. Local sourcing. 
            Charitable support. Partnership with 
            government and local NGOs. There are 
            lots of ways for us to make a difference 
            and we are working continuously to 
            make them happen.
            `
        } ,{
            h: 'A commitment to our customers',
            p: `Wherever our customers want to connect with us we're focused on delivering 
            a great experience. That means making 
            sure an authentic brand offer is backed 
            up by consistently high operations, 
            whether that's defining clear service 
            standards, adhering to global safety 
            standards, protecting customer rights, 
            and/or making it easy for our customers to talk to us.`
        },
    ]
}


const ar = {
    h: 'نهج مسؤول للأعمال',
    p: `كشركة عائلية تركز على النمو على المدى الطويل ، تدرك شبير الحاجة إلى العمل بمسؤولية وبشكل مستدام. هناك الكثير من الطرق التي نساهم بها:`,
    list: [
        {
            h: 'التزامنا تجاه موظفينا',
            p: `ندير مجموعة من البرامج المصممة لتطوير وتمكين ومكافأة وتكريم موظفينا ، وضمان الاستمتاع بهم بتوازن جيد بين العمل والحياة. برنامج قيادة شبير. فوائد كبيرة. عمل مرن. نادي رياضي واجتماعي نشط. هذه مجرد بعض الأشياء التي نقوم بها لضمان ثقافة نابضة بالحياة وصحية في شبير.`
        },
        
        {
            h: 'التزامنا تجاه مجتمعنا',
            p: `بصفتنا أحد أكبر أصحاب العمل في هذا القطاع ، هناك العديد من الطرق التي يمكننا من خلالها إحداث فرق مستدام في المجتمعات التي نعمل فيها. إنشاء وظائف وتطوير المهارات. المصادر المحلية. الدعم الخيري. الشراكة مع الحكومة والمنظمات غير الحكومية المحلية. هناك الكثير من الطرق لنجعلنا نحدث فرقًا ونعمل باستمرار لجعلها تحدث.`
        },
        {
            h: 'التزامنا تجاه عملائنا',
            p: `أينما يرغب عملاؤنا في التواصل معنا ، نركز على تقديم تجربة رائعة. وهذا يعني التأكد من أن العرض التجاري الأصيل مدعوم بعمليات عالية الجودة باستمرار ، سواء كان ذلك تحديد معايير خدمة واضحة ، أو الالتزام بمعايير السلامة العالمية ، أو حماية حقوق العملاء ، و / أو تسهيل التحدث إلينا.`
        },
    ]
}

export const date = { en, ar }